import { FormControl } from '@mui/material';
import * as React from 'react';
import { Control, Controller, Validate } from 'react-hook-form';
import { Error } from 'ui';
import { errorMessages } from 'util/forms.util';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface Props {
  name: string;
  label: string;
  control?: Control;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<string | number>;
    minLength?: ValidationRule<string | number>;
    max?: ValidationRule<string | number>;
    min?: ValidationRule<string | number>;
    pattern?: ValidationRule<RegExp>;
    validate?: Validate<string | number> | Record<string, Validate<string | number>>;
  };
  dataCy?: string;
}

/**
 * Return a hidden input component.
 *
 * @param props name and label (optional: control, rules, dataCy).
 * @returns a hidden input component.
 */
export const InputHidden: React.FC<Props> = (props) => {
  return (
    <FormControl data-cy={props.dataCy}>
      <Controller
        control={props.control}
        name={props.name}
        rules={props.rules}
        render={({ field: {}, fieldState: { error } }) => (
          <>
            <input type="hidden" name={props.name} />
            <Error
              content={error ? `* ${props.label} ${error.message || errorMessages()[error.type]}` : ''}
              dataCy="validation-message"
            />
          </>
        )}
      />
    </FormControl>
  );
};
