import { Action, DueDiligenceStatus, Role } from '@gcv/shared';
import * as React from 'react';

const LayoutCrb = React.lazy(() => import('../apps/crb/layout-combination'));
const LayoutFi = React.lazy(() => import('../apps/fi/layout'));
const LayoutMonitoringReview = React.lazy(() => import('../apps/fi/layout-monitoring-review'));
const LayoutAccountReview = React.lazy(() => import('../apps/fi/layout-account-review'));
const LayoutFincenReport = React.lazy(() => import('../apps/fi/layout-fincen'));
const LayoutGcv = React.lazy(() => import('../apps/gcv/layout'));

const ErrorPage = React.lazy(() => import('../apps/shared/error'));
const Auth0Login = React.lazy(() => import('../apps/shared/auth0/auth0-login'));
const SSOFork = React.lazy(() => import('../apps/shared/auth0/auth0-sso-fork'));
const Logout = React.lazy(() => import('../apps/shared/auth0/logout'));
const Login = React.lazy(() => import('../apps/shared/auth0/auth0-login'));
const LoginSupport = React.lazy(() => import('../apps/shared/login-support'));
const TermsOfService = React.lazy(() => import('../apps/shared/terms-of-service'));
const ComponentTestBed = React.lazy(() => import('../apps/shared/component-testbed'));

const CrbDashboard = React.lazy(() => import('../apps/crb/dashboard'));
const CrbDailySummaryDetail = React.lazy(() => import('../apps/crb/dashboard/pages/daily-summary-detail'));
const CrbDeposits = React.lazy(() => import('../apps/crb/dashboard/pages/record-deposit'));
const CrbQuestionnaires = React.lazy(() => import('../apps/crb/questionnaires-inbox'));
const CrbQuestionnaireEdit = React.lazy(
  () => import('../apps/crb/questionnaires-inbox/pages/questionnaire-edit')
);
const CrbQuestionnaireComplete = React.lazy(
  () => import('../apps/crb/questionnaires-inbox/pages/questionnaire-complete')
);
const CrbMarketplaceGallery = React.lazy(() => import('../apps/crb/marketplace'));
const CrbMyProviders = React.lazy(() => import('../apps/crb/my-providers'));
const CrbProviderDetails = React.lazy(() => import('../apps/crb/my-providers/pages/provider-details'));
const Requirement = React.lazy(() => import('../apps/crb/my-providers/pages/provider-details/requirement'));
const CrbCompany = React.lazy(() => import('../apps/crb/company-profile'));
const CrbUsers = React.lazy(() => import('../apps/crb/users'));
const CrbOnboarding = React.lazy(() => import('../apps/crb/onboarding'));
const CrbOnboardingTwo = React.lazy(() => import('../apps/crb/onboarding-two'));
const CrbIdentityVerify = React.lazy(() => import('../apps/crb/onboarding/pages/id-verification'));
const CrbGroupDetails = React.lazy(() => import('../apps/crb/users/pages/group-details'));
const CrbUserSettings = React.lazy(() => import('../apps/crb/users/pages/user-settings'));
const CrbWelcome = React.lazy(() => import('../apps/crb/welcome'));
const CrbInbox = React.lazy(() => import('../apps/crb/inbox'));
const CrbConnectOrg = React.lazy(() => import('../apps/crb/connect-org'));

const FiDashboard = React.lazy(() => import('../apps/fi/dashboard'));
const FiSettings = React.lazy(() => import('../apps/fi/settings'));
const FiSettingsCustomPeers = React.lazy(() => import('../apps/fi/settings/pages/custom-peers'));
const FiSettingsTemplateDetails = React.lazy(
  () => import('../apps/fi/settings/pages/due-diligence-template-details')
);
const FiDeposits = React.lazy(() => import('../apps/fi/deposits'));
const FiAccounts = React.lazy(() => import('../apps/fi/accounts'));
const FiAccountSummary = React.lazy(() => import('../apps/fi/accounts/pages/summary'));
const FiActiveAccount = FiAccountSummary;
const FiArchivedAccounts = React.lazy(() => import('../apps/fi/accounts/pages/archived'));
const FiReviewAccount = React.lazy(() => import('../apps/fi/accounts/pages/review'));
const FiViewQuestionnaire = React.lazy(() => import('../apps/fi/accounts/pages/questionnaire'));
const FiFincen = React.lazy(() => import('../apps/fi/fincen'));
const FiReports = React.lazy(() => import('../apps/fi/reports'));
const FiMonitoring = React.lazy(() => import('../apps/fi/monitoring'));
const FiMonitoringReview = React.lazy(() => import('../apps/fi/monitoring/pages/review'));
const FiTaskQuestionnaireOverview = React.lazy(() => import('../apps/fi/tasks/pages/questionnaire-overview'));
const FiUsers = React.lazy(() => import('../apps/fi/users'));
const FiGroupDetails = React.lazy(() => import('../apps/fi/users/pages/group-details'));
const FiUserSettings = React.lazy(() => import('../apps/fi/users/pages/user-settings'));
const FiViewRequirement = React.lazy(() => import('../apps/fi/accounts/pages/requirement'));
const FiViewOtherRequirement = React.lazy(() => import('../apps/fi/accounts/pages/requirement/pages/other'));
const FiFincenSar = React.lazy(() => import('../apps/fi/fincen/pages/sar'));
const FiFincenCtr = React.lazy(() => import('../apps/fi/fincen/pages/ctr'));
const FiQuestionnairesDetails = React.lazy(() => import('../apps/fi/settings/pages/questionnaires-details'));
const FiData = React.lazy(() => import('../apps/fi/data'));
const FiTasks = React.lazy(() => import('../apps/fi/tasks'));
const FiDailySummaries = React.lazy(() => import('../apps/fi/daily-summaries'));
const FiDailySummariesDetails = React.lazy(
  () => import('../apps/fi/daily-summaries/pages/daily-summary-detail')
);

const GcvDashboard = React.lazy(() => import('../apps/gcv/gcv-dashboard'));
const GcvDeposits = React.lazy(() => import('../apps/gcv/edit-deposit'));
const GcvSales = React.lazy(() => import('../apps/gcv/edit-sale'));
const GcvUsers = React.lazy(() => import('../apps/gcv/view-users'));
const GcvUserEdit = React.lazy(() => import('../apps/gcv/view-users/pages/edit-user'));
const GcvFinCEN = React.lazy(() => import('../apps/gcv/fincen-reports'));
const GcvIngestionReport = React.lazy(() => import('../apps/gcv/ingestion-reports'));
const GcvCRE = React.lazy(() => import('../apps/gcv/cre'));
const GcvCREStateDetails = React.lazy(() => import('../apps/gcv/cre/pages/state-context'));
const GcvPosIngestionReports = React.lazy(
  () => import('../apps/gcv/ingestion-reports/pages/pos-ingestion-reports')
);
const GcvDataGeneration = React.lazy(() => import('../apps/gcv/data-generation'));
const GcvOrganizations = React.lazy(() => import('../apps/gcv/view-organizations/index'));
const GcvViewBank = React.lazy(() => import('../apps/gcv/view-organizations/pages/viewBank'));
const GcvViewDispensary = React.lazy(() => import('../apps/gcv/view-organizations/pages/viewDispensary'));
const GcvCrbRequirement = React.lazy(
  () =>
    import(
      '../apps/gcv/view-organizations/pages/viewDispensary/pages/service-providers/pages/gcv-crb-requirement'
    )
);
const GcvCrbServiceProvider = React.lazy(
  () => import('../apps/gcv/view-organizations/pages/viewDispensary/pages/service-providers')
);
const GCVManageMuo = React.lazy(
  () => import('../apps/gcv/view-organizations/pages/viewBank/pages/manage-muo')
);
const GCVManageAccess = React.lazy(() => import('../apps/gcv/access'));
const GCVViewAccess = React.lazy(() => import('../apps/gcv/access/tabs/manage-access/pages/edit-access'));

export interface RouteType {
  path: string;
  exact?: boolean;
  component?: React.FC<unknown>;
  wrapperComponent?: React.FC<unknown>;
  children?: RouteType[];
  type?: 'crb' | 'fi' | 'gcv'; //define user type allowed to access route, defaults to none
  roles?: Role[]; //define user roles allowed to access route, defaults to all
  allowedStatuses?: DueDiligenceStatus[]; //define org roles allowed to access route, defaults to all
  requiredAction?: Action;
}

export const routes: RouteType[] = [
  { component: Auth0Login, exact: true, path: 'authorize' },
  { component: SSOFork, exact: true, path: 'sso' },
  { component: Logout, exact: true, path: 'logout' },
  { component: ErrorPage, path: 'error' },
  {
    component: Login,
    exact: true,
    path: ''
  },
  {
    component: Login,
    path: 'login',
    children: [
      {
        path: 'support',
        roles: ['gcv_customer_support'],
        component: LoginSupport
      }
    ]
  },
  {
    component: TermsOfService,
    path: 'tos'
  },
  {
    component: ComponentTestBed,
    path: 'testbed'
  },
  {
    path: 'secure',
    children: [
      {
        type: 'crb',
        path: 'crb/welcome',
        component: CrbWelcome
      },
      {
        type: 'crb',
        path: 'crb/connect-org',
        component: CrbConnectOrg
      },
      {
        type: 'crb',
        path: 'crb/:crbId?',
        wrapperComponent: LayoutCrb,
        children: [
          {
            type: 'crb',
            path: 'onboarding',
            roles: ['dispensary_account_owner'],
            children: [
              {
                type: 'crb',
                path: 'bank/:bank_id/template/:template_id/result/:template_result_id',
                roles: ['dispensary_account_owner'],
                component: CrbOnboarding
              }
            ]
          },
          {
            type: 'crb',
            path: 'provider-onboarding',
            roles: ['dispensary_account_owner'],
            children: [
              {
                type: 'crb',
                path: 'bank/:bank_id/template/:template_id/result/:template_result_id',
                roles: ['dispensary_account_owner'],
                component: CrbOnboardingTwo
              }
            ]
          },
          {
            type: 'crb',
            path: 'identity-verify',
            component: CrbIdentityVerify
          },
          {
            type: 'crb',
            path: 'dashboard',
            component: CrbDashboard,
            children: [
              {
                type: 'crb',
                path: 'daily-summaries/:date',
                component: CrbDailySummaryDetail
              },
              {
                type: 'crb',
                path: 'deposit',
                component: CrbDeposits,
                requiredAction: 'deposit_view_all'
              }
            ]
          },
          {
            type: 'crb',
            path: 'inbox',
            component: CrbInbox,
            children: [
              {
                type: 'crb',
                path: 'questionnaire-edit/:id',
                component: CrbQuestionnaireEdit
              },
              {
                type: 'crb',
                path: 'questionnaire-complete/:id',
                component: CrbQuestionnaireComplete
              }
            ]
          },
          {
            path: 'questionnaires',
            component: CrbQuestionnaires,
            requiredAction: 'organizational_details_update',
            children: [
              {
                type: 'crb',
                path: 'questionnaire-edit/:id',
                component: CrbQuestionnaireEdit
              },
              {
                type: 'crb',
                path: 'questionnaire-complete/:id',
                component: CrbQuestionnaireComplete
              }
            ]
          },
          {
            path: 'marketplace',
            component: CrbMarketplaceGallery,
            requiredAction: 'organizational_details_update',
            children: []
          },
          {
            path: 'my-providers',
            component: CrbMyProviders,
            requiredAction: 'organizational_details_update',
            children: [
              {
                type: 'crb',
                path: 'provider-details/:id',
                component: CrbProviderDetails
              },
              {
                type: 'crb',
                path: 'provider-details/:providerId/requirement/:requirementId',
                component: Requirement
              }
            ]
          },
          {
            path: 'company',
            component: CrbCompany,
            requiredAction: 'organizational_details_update',
            children: []
          },
          {
            type: 'crb',
            path: 'users',
            component: CrbUsers,
            requiredAction: 'user_add',
            children: [
              {
                type: 'crb',
                path: 'group-details/:id',
                component: CrbGroupDetails
              },
              {
                type: 'crb',
                path: 'settings',
                component: CrbUserSettings
              }
            ]
          }
        ]
      },
      {
        type: 'fi',
        path: 'fi/accounts/:dispensary_id/review',
        wrapperComponent: LayoutAccountReview,
        component: FiReviewAccount
      },
      {
        type: 'fi',
        path: 'fi/monitoring/review/:review_id',
        wrapperComponent: LayoutMonitoringReview,
        component: FiMonitoringReview
      },
      {
        type: 'fi',
        path: 'fi/fincen/sar/:id',
        wrapperComponent: LayoutFincenReport,
        component: FiFincenSar
      },
      {
        type: 'fi',
        path: 'fi/fincen/ctr/:id',
        wrapperComponent: LayoutFincenReport,
        component: FiFincenCtr
      },
      {
        type: 'fi',
        path: 'fi',
        wrapperComponent: LayoutFi,
        children: [
          {
            type: 'fi',
            path: 'dashboard',
            component: FiDashboard
          },
          {
            type: 'fi',
            path: 'data',
            component: FiData
          },
          {
            type: 'fi',
            path: 'data/daily-summaries',
            component: FiDailySummaries,
            children: [
              {
                type: 'fi',
                path: 'details/:dispensary_id/:date',
                component: FiDailySummariesDetails
              }
            ]
          },
          {
            type: 'fi',
            path: 'deposits',
            component: FiDeposits
          },
          {
            type: 'fi',
            path: 'accounts',
            component: FiAccounts,
            children: [
              {
                type: 'fi',
                path: 'archived',
                component: FiArchivedAccounts
              },
              {
                type: 'fi',
                path: ':dispensary_id/view',
                component: FiActiveAccount
              },
              {
                type: 'fi',
                path: ':dispensary_id/view/requirement/other/:requirement_id',
                component: FiViewOtherRequirement
              },
              {
                type: 'fi',
                path: ':dispensary_id/view/requirement/:requirement_id',
                component: FiViewRequirement
              },
              {
                type: 'fi',
                path: ':dispensary_id/questionnaire/:questionnaire_id/response/:response_id',
                component: FiViewQuestionnaire
              }
            ]
          },
          {
            type: 'fi',
            path: 'fincen',
            component: FiFincen,
            children: [
              {
                type: 'fi',
                path: 'sar/:id',
                component: FiFincenSar
              },
              {
                type: 'fi',
                path: 'ctr/:id',
                component: FiFincenCtr
              }
            ]
          },
          {
            type: 'fi',
            path: 'reports',
            component: FiReports
          },
          {
            type: 'fi',
            path: 'monitoring',
            component: FiMonitoring
          },
          {
            type: 'fi',
            path: 'tasks',
            component: FiTasks,
            children: [
              {
                type: 'fi',
                path: 'questionnaire/overview/:questionnaire_id/result/:response_id',
                component: FiTaskQuestionnaireOverview
              }
            ]
          },
          {
            type: 'fi',
            path: 'settings',
            component: FiSettings,
            children: [
              {
                type: 'fi',
                path: 'peer-groups/:dispensary_id',
                component: FiSettingsCustomPeers
              },
              {
                type: 'fi',
                path: 'due-diligence-template/:template_id',
                component: FiSettingsTemplateDetails
              },
              {
                type: 'fi',
                path: 'questionnaires-details/:questionnaire_id',
                component: FiQuestionnairesDetails
              }
            ]
          },
          {
            type: 'fi',
            path: 'users',
            component: FiUsers,
            requiredAction: 'user_add',
            children: [
              {
                type: 'fi',
                path: 'group-details/:id',
                component: FiGroupDetails
              },
              {
                type: 'fi',
                path: 'settings',
                component: FiUserSettings
              }
            ]
          },
          {
            type: 'fi',
            path: 'settings',
            component: FiSettings
          }
        ]
      },
      {
        type: 'gcv',
        path: 'gcv',
        wrapperComponent: LayoutGcv,
        children: [
          {
            type: 'gcv',
            path: 'dashboard',
            component: GcvDashboard
          },
          {
            type: 'gcv',
            path: 'deposits',
            component: GcvDeposits
          },
          {
            type: 'gcv',
            path: 'sales',
            component: GcvSales
          },
          {
            type: 'gcv',
            path: 'users',
            component: GcvUsers,
            children: [
              {
                type: 'gcv',
                path: ':id',
                component: GcvUserEdit
              }
            ]
          },
          {
            type: 'gcv',
            path: 'fincen',
            component: GcvFinCEN
          },
          {
            type: 'gcv',
            path: 'organizations',
            component: GcvOrganizations,
            children: [
              {
                type: 'gcv',
                path: 'banks/:id',
                component: GcvViewBank,
                children: [{ type: 'gcv', path: 'muos/:muoId', component: GCVManageMuo }]
              },
              {
                type: 'gcv',
                path: 'dispensaries/:id',
                component: GcvViewDispensary,
                children: [
                  {
                    type: 'gcv',
                    path: 'service-providers/:serviceProviderId',
                    component: GcvCrbServiceProvider,
                    children: [
                      {
                        type: 'gcv',
                        path: 'requirements/:requirementId',
                        component: GcvCrbRequirement
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'gcv',
            path: 'data-generation',
            component: GcvDataGeneration
          },
          {
            type: 'gcv',
            path: 'ingestion',
            component: GcvIngestionReport,
            children: [
              {
                type: 'gcv',
                path: ':posType',
                component: GcvPosIngestionReports
              }
            ]
          },
          {
            type: 'gcv',
            path: 'cre',
            component: GcvCRE,
            children: [
              {
                type: 'gcv',
                path: 'states/:state_id',
                component: GcvCREStateDetails
              }
            ]
          },
          {
            type: 'gcv',
            path: 'access',
            component: GCVManageAccess,
            children: [
              {
                type: 'gcv',
                path: ':client_id',
                component: GCVViewAccess
              }
            ]
          }
        ]
      }
    ]
  }
];
