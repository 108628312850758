import {
  FiQuestionnaireSystemTaskData,
  IANATimezones,
  MinifiedTask,
  OrganizationRoleResolver,
  RequestForDocumentTaskData,
  TaskCategory,
  TaskInputRequest,
  TaskInternalExternal,
  TaskInternalType,
  TaskStatus,
  TaskUser,
  User
} from '@gcv/shared';
import { lookupQuestionnaireFrequencyLabel } from 'domain/consts/due-diligence';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { AppPage, AppViewStateStore, TimePeriod, isEqualTimePeriod } from 'stores/AppViewStateStore';
import { FiBankStore } from 'stores/FiBankStore';
import { NotificationStore } from 'stores/NotificationStore';
import { SnackbarStore } from 'stores/SnackBarStore';
import { UserStore } from 'stores/UserStore';
import { FilterListChild, FilterListItem } from 'ui';
import palette from 'ui/theme/palette';
import { DateTimeHelpers } from 'util/dateTime.util';
import { filterUtil } from 'util/filter.util';
import { getTaskStatusText, removeUuid } from 'util/format.util';
import { searchUtil } from 'util/search.util';
import { capitalizeVariables } from 'util/strings.util';
import {
  DecoratedTask,
  TaskDetails,
  TaskDrawerField,
  TaskRow,
  hasSubtasks,
  isGroupedTask
} from './tasks.model';
import { FiTasksRepo, PM } from './tasks.repo';

export interface VM {
  bankId: string;
  currentTask: TaskDetails | null;
  internalFilteredTasks: TaskRow[];
  externalFilteredTasks: TaskRow[];
  filters: FilterListChild[];
  isBankAdmin: boolean;
  isCompleteModalOpen: boolean;
  isCreateModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isDetailsDrawerOpen: boolean;
  isQuestionnaireDrawerOpen: boolean;
  isLoading: boolean;
  isFiltering: boolean;
  searchTerm: string;
  tasks: TaskRow[];
  timezone: IANATimezones;
  showAutogen: boolean;
}

@injectable()
export class FiTasksPresenter {
  @inject(FiTasksRepo)
  private repo: FiTasksRepo;

  @inject(AppViewStateStore)
  private appViewStore: AppViewStateStore;

  @inject(FiBankStore)
  private bankStore: FiBankStore;

  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  @inject(UserStore)
  private userStore: UserStore;

  @inject(OrganizationRoleResolver)
  private resolver: OrganizationRoleResolver;

  @inject(NotificationStore)
  private notificationStore: NotificationStore;

  public viewModel: VM = {
    bankId: '',
    currentTask: null,
    filters: [],
    internalFilteredTasks: [],
    externalFilteredTasks: [],
    isBankAdmin: false,
    isCompleteModalOpen: false,
    isCreateModalOpen: false,
    isDeleteModalOpen: false,
    isDetailsDrawerOpen: false,
    isQuestionnaireDrawerOpen: false,
    isLoading: false,
    isFiltering: true,
    searchTerm: '',
    tasks: [],
    timezone: IANATimezones.America_NewYork,
    showAutogen: false
  };

  public externalDrawerFields = (): Record<string, TaskDrawerField> => {
    const composeStatusLabel = (task: TaskDetails | null): string => {
      // The UI design wants the past participle of "complete". The enum values are grammatically
      // inconsistent: COMPLETE should follow the same pattern as CANCELLED, FAILED, and DELETED.
      return task?.status === TaskStatus.COMPLETE
        ? 'Completed'
        : capitalizeVariables(task?.status ?? '') ?? '--';
    };

    return {
      status: {
        label: 'Status',
        value: composeStatusLabel(this.viewModel.currentTask),
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 33,
        color: this.viewModel.currentTask?.status === TaskStatus.COMPLETE ? palette.primary.main : undefined
      },
      dueDate: {
        label: 'Due Date',
        value:
          DateTimeHelpers.formatISOToTableDateString(
            this.viewModel.currentTask?.dueDate ?? '',
            this.bankStore.bank.iana_timezone
          ) ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 33
      },
      createdDate: {
        label: 'Sent On',
        value:
          DateTimeHelpers.formatISOToTableDateString(
            this.viewModel.currentTask?.createdDate ?? '',
            this.bankStore.bank.iana_timezone
          ) ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 33
      },
      requirementName: {
        label: 'Requirement',
        value: (this.viewModel.currentTask?.data as RequestForDocumentTaskData)?.requirement_name ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 33
      },
      account: {
        label: 'Account',
        value: this.viewModel.currentTask?.account ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 100
      },
      assignee: {
        label: 'Assignee',
        value: this.viewModel.currentTask?.assignee ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 50
      },
      completedBy: {
        label: 'Completed By',
        value: this.viewModel.currentTask?.completedBy ?? '--',
        state: [TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 50
      },
      description: {
        label: 'Task Description',
        value: this.viewModel.currentTask ? removeUuid(this.viewModel.currentTask?.description) : '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 100
      },
      lastReminder: {
        label: 'Last Reminder Sent On',
        value:
          this.viewModel.currentTask && this.viewModel.currentTask.lastReminderDate
            ? DateTimeHelpers.formatISOToTableDateString(
                this.viewModel.currentTask.lastReminderDate,
                this.bankStore.bank.iana_timezone
              )
            : '--',
        state: [TaskStatus.OPEN],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 50
      },
      nextReminder: {
        label: 'Next Reminder Scheduled For',
        value:
          this.viewModel.currentTask && this.viewModel.currentTask.nextReminderDate
            ? DateTimeHelpers.formatISOToTableDateString(
                this.viewModel.currentTask.nextReminderDate,
                this.bankStore.bank.iana_timezone
              )
            : '--',
        state: [TaskStatus.OPEN],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 50
      },
      createdBy: {
        label: 'Created By',
        value: this.viewModel.currentTask?.createdBy ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 33
      }
    };
  };

  public taskDrawerFields = (): Record<string, TaskDrawerField> => {
    const composeStatusLabel = (task: TaskDetails | null): string => {
      // The UI design wants the past participle of "complete". The enum values are grammatically
      // inconsistent: COMPLETE should follow the same pattern as CANCELLED, FAILED, and DELETED.
      return task?.status === TaskStatus.COMPLETE
        ? 'Completed'
        : capitalizeVariables(task?.status ?? '') ?? '--';
    };

    return {
      name: {
        label: 'Task Name',
        value: this.viewModel.currentTask?.name ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 100
      },
      status: {
        label: 'Status',
        value: composeStatusLabel(this.viewModel.currentTask),
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 100,
        color: this.viewModel.currentTask?.status === TaskStatus.COMPLETE ? palette.primary.main : undefined
      },
      assignee: {
        label: 'Assignee',
        value: this.viewModel.currentTask?.assignee ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER],
        width: 100
      },
      completedBy: {
        label: 'Completed By',
        value: this.viewModel.currentTask?.completedBy ?? '--',
        state: [TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 100
      },
      description: {
        label: 'Task Description',
        value: this.viewModel.currentTask ? removeUuid(this.viewModel.currentTask?.description) : '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 100
      },
      lastReminder: {
        label: 'Last Reminder Sent On',
        value:
          this.viewModel.currentTask && this.viewModel.currentTask.lastReminderDate
            ? DateTimeHelpers.formatISOToTableDateString(
                this.viewModel.currentTask.lastReminderDate,
                this.bankStore.bank.iana_timezone
              )
            : '--',
        state: [TaskStatus.OPEN],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 50
      },
      nextReminder: {
        label: 'Next Reminder Scheduled For',
        value:
          this.viewModel.currentTask && this.viewModel.currentTask.nextReminderDate
            ? DateTimeHelpers.formatISOToTableDateString(
                this.viewModel.currentTask.nextReminderDate,
                this.bankStore.bank.iana_timezone
              )
            : '--',
        state: [TaskStatus.OPEN],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 50
      },
      account: {
        label: 'Account',
        value: this.viewModel.currentTask?.account ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 100
      },
      associated: {
        label: 'Associated With',
        value:
          this.viewModel.currentTask?.associatedWith === 'fincen_reports'
            ? 'FinCEN Reports'
            : capitalizeVariables(this.viewModel.currentTask?.associatedWith ?? '') ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 100
      },
      dueDate: {
        label: 'Due Date',
        value:
          DateTimeHelpers.formatISOToTableDateString(
            this.viewModel.currentTask?.dueDate ?? '',
            this.bankStore.bank.iana_timezone
          ) ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 33
      },
      createdDate: {
        label: 'Created',
        value:
          DateTimeHelpers.formatISOToTableDateString(
            this.viewModel.currentTask?.createdDate ?? '',
            this.bankStore.bank.iana_timezone
          ) ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 33
      },
      createdBy: {
        label: 'Created By',
        value: this.viewModel.currentTask?.createdBy ?? '--',
        state: [TaskStatus.OPEN, TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 33
      },
      completedDate: {
        label: 'Completed',
        value:
          DateTimeHelpers.formatISOToTableDateString(
            this.viewModel.currentTask?.completedDate ?? '',
            this.bankStore.bank.iana_timezone
          ) ?? '--',
        state: [TaskStatus.COMPLETE],
        type: [TaskCategory.USER, TaskCategory.SYSTEM],
        width: 33
      },
      note: {
        label: 'Completion Note',
        value: !!this.viewModel.currentTask?.completedNote?.length
          ? this.viewModel.currentTask?.completedNote
          : '--',
        state: [TaskStatus.COMPLETE],
        type: [TaskCategory.USER],
        width: 100
      }
    };
  };

  constructor() {
    makeAutoObservable(this);
  }

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  private mapTaskToTaskRow = (decoratedTask: DecoratedTask): TaskRow => {
    const tasks = decoratedTask.tasks;

    const assignee = this.repo.getAssigneeName(
      tasks[0].assigned_users,
      tasks[0].assigned_group_ids,
      tasks[0].assigned_groups
    );
    const createdByUser =
      tasks[0].created_by_user && tasks[0].created_by_user === TaskUser.AUTO_GENERATED_USER
        ? 'Auto Generated'
        : tasks[0].created_by_user && tasks[0].created_by_user.firstName && tasks[0].created_by_user.lastName
        ? `${tasks[0].created_by_user.firstName} ${tasks[0].created_by_user.lastName}`
        : '';
    if (decoratedTask.isGrouped) {
      const completedDates = [...new Set(tasks.map((task) => task.completed_on))];
      const completedUsers = [
        ...new Set(
          tasks.map((task) => {
            const user = task.completed_by_user as User;

            if (!user) {
              return '--';
            }

            return user.firstName + ' ' + user.lastName;
          })
        )
      ];
      const completedOn = DateTimeHelpers.formatISOToTableDateString(
        completedDates.sort().reverse()[0] ?? '',
        this.bankStore.bank.iana_timezone
      );
      const completedBy = completedUsers.length > 1 ? 'Multiple Users' : completedUsers[0];
      const reoccuring =
        tasks[0].data && (tasks[0].data as FiQuestionnaireSystemTaskData).questionnaire_fi
          ? (tasks[0].data as FiQuestionnaireSystemTaskData).questionnaire_fi?.frequency
          : null;
      const frequency = reoccuring ? lookupQuestionnaireFrequencyLabel(reoccuring) : 'One-Time';
      let account = '--';
      if (tasks.length > 1) {
        account = 'Multiple Accounts';
      } else if (tasks[0].assigned_org.orgType === 'bank') {
        account = tasks[0].account?.name ?? '--';
      } else {
        account = tasks[0].assigned_org?.name;
      }
      return {
        account: account,
        assignee: assignee,
        associatedWith: tasks[0].associated_with ?? '--',
        completed: completedOn ?? '--',
        completedBy: completedBy ?? '--',
        created: DateTimeHelpers.formatISOToTableDateString(
          tasks[0].date_created,
          this.bankStore.bank.iana_timezone
        ),
        createdBy: createdByUser,
        dueDate: tasks[0].process_date,
        id: decoratedTask.groupKey,
        taskName: tasks[0].title ?? '--',
        createdTimestamp: new Date(tasks[0].date_created).getTime(),
        completedTimestamp: tasks[0].completed_on ? new Date(tasks[0].completed_on).getTime() : undefined,
        groups: tasks[0].assigned_group_ids ?? [],
        status: this.getGroupedStatus(tasks),
        sentOn: DateTimeHelpers.formatISOToTableDateString(
          tasks[0].date_created,
          this.bankStore.bank.iana_timezone
        ),
        frequency: frequency,
        type: tasks[0].internal_type === TaskInternalType.QUESTIONNAIRE ? 'questionnaire' : 'task'
      };
    } else {
      let account = '--';
      if (tasks[0].assigned_org.orgType === 'bank') {
        account = tasks[0].account?.name ?? '--';
      } else {
        account = tasks[0].assigned_org?.name;
      }
      const completedUser =
        tasks[0].completed_by_user &&
        (tasks[0].completed_by_user as User).firstName + ' ' + (tasks[0].completed_by_user as User).lastName;
      const reoccuring =
        tasks[0].data && (tasks[0].data as FiQuestionnaireSystemTaskData).questionnaire_fi
          ? (tasks[0].data as FiQuestionnaireSystemTaskData).questionnaire_fi?.frequency
          : null;
      const frequency = reoccuring ? lookupQuestionnaireFrequencyLabel(reoccuring) : 'One-Time';
      return {
        account: account ? account : '--',
        assignee: assignee,
        associatedWith: tasks[0].associated_with ?? '--',
        completed: DateTimeHelpers.formatISOToTableDateString(
          tasks[0].completed_on ?? '',
          this.bankStore.bank.iana_timezone
        ),
        completedBy: completedUser ? completedUser : '--',
        created: DateTimeHelpers.formatISOToTableDateString(
          tasks[0].date_created,
          this.bankStore.bank.iana_timezone
        ),
        createdBy: createdByUser,
        dueDate: tasks[0].process_date,
        id: tasks[0].id,
        taskName: tasks[0].title ?? '--',
        createdTimestamp: new Date(tasks[0].date_created).getTime(),
        completedTimestamp: tasks[0].completed_on ? new Date(tasks[0].completed_on).getTime() : undefined,
        groups: tasks[0].assigned_group_ids ?? [],
        status: tasks[0].status ?? '--',
        sentOn: DateTimeHelpers.formatISOToTableDateString(
          tasks[0].date_created,
          this.bankStore.bank.iana_timezone
        ),
        frequency: frequency,
        type: tasks[0].internal_type === TaskInternalType.QUESTIONNAIRE ? 'questionnaire' : 'task'
      };
    }
  };

  public load = action(
    async (taskDirection: TaskInternalExternal, keepSearch: boolean, currentTab: string) => {
      observe(this.repo, 'programmersModel', (obj) => {
        const programmersModel = obj.newValue as PM;
        const taskList: TaskRow[] = programmersModel.tasks.map((t) => this.mapTaskToTaskRow(t));
        this.updateViewModel({
          currentTask: programmersModel.currentTask,
          tasks: taskList,
          isLoading: programmersModel.isLoading
        });
      });

      await this.repo.load(
        this.bankStore.bank.id,
        taskDirection,
        this.appViewStore.tasksTimePeriod[currentTab].timeRange.start,
        this.appViewStore.tasksTimePeriod[currentTab].timeRange.end,
        [TaskStatus.OPEN, TaskStatus.PROCESSING, TaskStatus.COMPLETE]
      );

      this.updateViewModel({
        bankId: this.bankStore.bank.id,
        timezone: this.bankStore.bank.iana_timezone,
        isBankAdmin:
          this.resolver.userHasRole(this.bankStore.bank.groups, this.userStore.user, 'bank_admin') ||
          this.resolver.userHasRole(this.bankStore.bank.groups, this.userStore.user, 'bank_primary_contact'),
        searchTerm: keepSearch ? this.appViewStore.tasksSearchTerm : ''
      });

      this.refilterResults(taskDirection);
    }
  );

  public getGroupedStatus = (tasks: MinifiedTask[]) => {
    const statuses = tasks.map((task) => task.status);

    if (statuses.includes(TaskStatus.PROCESSING)) {
      return TaskStatus.PROCESSING;
    } else if (statuses.includes(TaskStatus.OPEN) && statuses.includes(TaskStatus.COMPLETE)) {
      return TaskStatus.PROCESSING;
    } else if (statuses.includes(TaskStatus.COMPLETE)) {
      return TaskStatus.COMPLETE;
    } else {
      return TaskStatus.OPEN;
    }
  };

  public showLoad = action((load: boolean) => {
    this.viewModel.isLoading = load;
  });

  public loadTask = async (taskId: string) => {
    await this.repo.getTaskById(this.bankStore.bank.id, taskId);
  };

  public setTimePeriod = (data: TimePeriod, currentTab: string) => {
    if (!isEqualTimePeriod(data, this.appViewStore.tasksTimePeriod[currentTab])) {
      this.appViewStore.setTimePeriod(
        {
          timeRange: data.timeRange,
          dateRange: data.dateRange,
          value: data.value
        },
        AppPage.tasks,
        currentTab
      );
    }
  };

  public updateInternalFilteredTasks = action((filteredList: TaskRow[]) => {
    this.viewModel.internalFilteredTasks = filteredList;
  });

  public updateExternalFilteredTasks = action((filteredList: TaskRow[]) => {
    this.viewModel.externalFilteredTasks = filteredList;
  });

  public createNewTask = async (payload: TaskInputRequest) => {
    await this.repo.createNewTask(this.bankStore.bank.id, payload).catch(() => {
      this.snackbarStore.showErrorSnackbarMessage(
        'There was a problem creating the task. Please try again or contact our help desk!'
      );
    });
  };

  public toggleDetailsDrawer = (isOpen: boolean) => {
    this.updateViewModel({ isDetailsDrawerOpen: isOpen });
  };

  public toggleQuestionnaireDetailsDrawer = (isOpen: boolean) => {
    this.updateViewModel({ isQuestionnaireDrawerOpen: isOpen });
  };

  public toggleDeleteModal = (isOpen: boolean) => {
    this.updateViewModel({ isDeleteModalOpen: isOpen });
  };

  public toggleCompleteModal = (isOpen: boolean) => {
    this.updateViewModel({ isCompleteModalOpen: isOpen });
  };

  public isSupportUser = () => {
    return this.resolver.userHasRole(this.bankStore.bank.groups, this.userStore.user, 'gcv_customer_support');
  };

  public isGroupedTask = () => {
    if (!this.viewModel.currentTask) {
      return false;
    }

    const isGrouped = isGroupedTask(this.viewModel.currentTask);
    const hasMultiple = hasSubtasks(this.viewModel.currentTask);

    return isGrouped && hasMultiple;
  };

  public isOpenUserTask = () => {
    if (!this.viewModel.currentTask) {
      return false;
    }

    const isOpen = this.viewModel.currentTask.status === TaskStatus.OPEN;
    const isUser = this.viewModel.currentTask.taskCategory === TaskCategory.USER;

    return isOpen && isUser;
  };

  public isOpenSystemTask = () => {
    if (!this.viewModel.currentTask) {
      return false;
    }

    const isOpen = this.viewModel.currentTask.status === TaskStatus.OPEN;
    const isSystem = this.viewModel.currentTask.taskCategory === TaskCategory.SYSTEM;
    const isGrouped = isGroupedTask(this.viewModel.currentTask);
    const hasMultiple = hasSubtasks(this.viewModel.currentTask);

    return isOpen && isSystem && (!isGrouped || !hasMultiple);
  };

  public fieldShouldRender = (field: TaskDrawerField, isExternal: boolean, fieldName: string) => {
    if (
      !this.viewModel.currentTask ||
      (isGroupedTask(this.viewModel.currentTask) && hasSubtasks(this.viewModel.currentTask))
    ) {
      return false;
    }
    if (
      this.viewModel.currentTask.internalType !== TaskInternalType.DOCUMENTS &&
      fieldName === 'requirementName'
    ) {
      return false;
    }

    return (
      this.viewModel.currentTask &&
      (field.state.includes(this.viewModel.currentTask.status) ||
        [TaskStatus.OPEN, TaskStatus.COMPLETE].every((s) => {
          return field.state.includes(s);
        })) &&
      (field.type.includes(this.viewModel.currentTask.taskCategory) ||
        [TaskCategory.USER, TaskCategory.SYSTEM].every((s) => {
          return field.type.includes(s);
        }))
    );
  };

  public showCreateTaskModal = (isOpen: boolean) => {
    this.updateViewModel({ isCreateModalOpen: isOpen });
  };

  public refilterResults = (currentTab: TaskInternalExternal) => {
    this.viewModel.isFiltering = true;

    const currentFilters = this.viewModel.filters.map((s) => {
      const selectedValue = s.value === 'assignee_none' ? '--' : s.value === 'account_none' ? '--' : s.value;

      return {
        ...s,
        value: selectedValue
      };
    });

    if (currentTab === TaskInternalExternal.INTERNAL) {
      filterUtil(this.updateInternalFilteredTasks, this.viewModel.tasks, currentFilters);
      searchUtil(
        this.updateInternalFilteredTasks,
        this.viewModel.internalFilteredTasks,
        ['taskName', 'assignee', 'account', 'associatedWith', 'created', 'createdBy', 'dueDate'],
        this.viewModel.searchTerm
      );
    } else {
      filterUtil(this.updateExternalFilteredTasks, this.viewModel.tasks, currentFilters);
      searchUtil(
        this.updateExternalFilteredTasks,
        this.viewModel.externalFilteredTasks,
        ['taskName', 'assignee', 'account', 'associatedWith', 'created', 'createdBy', 'dueDate'],
        this.viewModel.searchTerm
      );
    }

    if (currentTab === TaskInternalExternal.INTERNAL) {
      this.filterAutogen(
        this.updateInternalFilteredTasks,
        this.viewModel.internalFilteredTasks,
        this.viewModel.showAutogen
      );
    }

    this.viewModel.isFiltering = false;
  };

  filterAutogen = (
    updateFilteredTasks: (filteredList: TaskRow[]) => void,
    filteredTasks: TaskRow[],
    showAutogen: boolean
  ) => {
    if (!showAutogen) {
      updateFilteredTasks(filteredTasks.filter((task) => task.createdBy !== 'Auto Generated'));
    }
  };

  public deleteCurrentTask = async (currentTab: TaskInternalExternal) => {
    this.updateViewModel({
      isDetailsDrawerOpen: false,
      isDeleteModalOpen: false
    });
    await this.repo.deleteCurrentTask(this.viewModel.bankId);
    await this.load(currentTab ? currentTab : TaskInternalExternal.INTERNAL, false, currentTab);
    this.refilterResults(currentTab);
    this.notificationStore.getAllUserNotifications(this.bankStore.bank.id, this.userStore.user.id);
  };

  public deleteCurrentQuestionnaire = async (currentTab: TaskInternalExternal) => {
    this.updateViewModel({
      isQuestionnaireDrawerOpen: false
    });
    await this.load(TaskInternalExternal.EXTERNAL, false, currentTab);
    this.refilterResults(currentTab);
  };

  public updateTask = async (
    status: TaskStatus,
    completed_note: string,
    currentTab: TaskInternalExternal
  ) => {
    this.viewModel.isLoading = true;
    this.updateViewModel({ isDetailsDrawerOpen: false, isCompleteModalOpen: false });
    await this.repo.updateCurrentTask(this.viewModel.bankId, status, completed_note, this.viewModel.timezone);
    this.refilterResults(currentTab);
    this.viewModel.isLoading = false;
  };

  public setSearchTerm = (searchTerm: string, currentTab: TaskInternalExternal) => {
    this.updateViewModel({ searchTerm });
    this.appViewStore.tasksSearchTerm = searchTerm;

    this.refilterResults(currentTab);
  };

  public setFilters = action((filters: FilterListChild[], currentTab: TaskInternalExternal) => {
    this.viewModel.filters = filters;
    this.appViewStore.tasksFilters[currentTab] = filters;

    this.refilterResults(currentTab);
  });

  public getGroupNames = (groupIds: string[]) => {
    const names: string[] = [];

    groupIds.forEach((id) => {
      names.push(this.bankStore.bank.groups.find((g) => g.id === id)?.name ?? '--');
    });

    return names;
  };

  public resetPage = () => {
    this.updateViewModel({
      searchTerm: '',
      filters: [],
      internalFilteredTasks: [],
      externalFilteredTasks: [],
      tasks: [],
      showAutogen: false
    });
  };

  getInitializedFilters = (currentTab: string, initialLoad?: boolean, fromQuestionnaire?: boolean) => {
    const filters: FilterListItem[] = [];
    const assignee = new Map(this.viewModel.tasks.map((t) => [t.assignee, t.assignee]));
    const account = new Map(this.viewModel.tasks.map((t) => [t.account, t.account]));
    const createdBy = new Map(
      this.viewModel.tasks
        .filter((user) => user.createdBy !== 'Auto Generated')
        .map((t) => [t.createdBy, t.createdBy])
    );
    const status = 'status';
    const frequency = 'frequency';
    const openFilterObj = {
      label: getTaskStatusText(TaskStatus.OPEN),
      value: TaskStatus.OPEN,
      selected: (initialLoad && !fromQuestionnaire) ?? false,
      parentValue: status
    };
    const inProgressFilterObj = {
      label: getTaskStatusText(TaskStatus.PROCESSING),
      value: TaskStatus.PROCESSING,
      selected: (initialLoad && !fromQuestionnaire) ?? false,
      parentValue: status
    };
    const completedFilterObj = {
      label: getTaskStatusText(TaskStatus.COMPLETE),
      value: TaskStatus.COMPLETE,
      parentValue: status
    };
    if (currentTab === TaskInternalExternal.INTERNAL) {
      filters.push({
        label: 'Status',
        value: status,
        children: [openFilterObj, inProgressFilterObj, completedFilterObj]
      });
      filters.push({
        label: 'Assignee',
        value: 'assignee',
        children: [...assignee]
          .sort((a, b) => {
            return a[0] < b[0] ? -1 : 1;
          })
          .map(([label, value]) => {
            const filterLabel = label === '--' ? 'None' : label;
            const filterValue = value === '--' ? 'assignee_none' : value;

            return { label: filterLabel, value: filterValue, parentValue: 'assignee' };
          })
      });
      filters.push({
        label: 'Account',
        value: 'account',
        children: [...account]
          .sort((a, b) => {
            return a[0] < b[0] ? -1 : 1;
          })
          .map(([label, value]) => {
            const filterLabel = label === '--' ? 'None' : label;
            const filterValue = value === '--' ? 'account_none' : value;

            return { label: filterLabel, value: filterValue, parentValue: 'account' };
          })
      });
      filters.push({
        label: 'Created By',
        value: 'createdBy',
        children: [...createdBy]
          .sort((a, b) => {
            return a[0] < b[0] ? -1 : 1;
          })
          .map(([label, value]) => {
            const filterLabel = label === '--' ? 'Auto Created' : label;
            const filterValue = value === '--' ? 'autoCreated' : value;

            return { label: filterLabel, value: filterValue, parentValue: 'createdBy' };
          })
      });
    } else {
      filters.push({
        label: 'Status',
        value: status,
        children: [openFilterObj, inProgressFilterObj, completedFilterObj]
      });
      filters.push({
        label: 'Type',
        value: 'type',
        children: [
          { label: 'Task', value: 'task', parentValue: 'type' },
          { label: 'Questionnaire', value: 'questionnaire', parentValue: 'type' }
        ]
      });
      filters.push({
        label: 'Frequency',
        value: frequency,
        children: [
          {
            label: 'Weekly',
            value: 'Weekly',
            parentValue: frequency
          },
          {
            label: 'Monthly',
            value: 'Monthly',
            parentValue: frequency
          },
          {
            label: 'Quarterly',
            value: 'Quarterly',
            parentValue: frequency
          },
          {
            label: 'Semi-Annually',
            value: 'Semi-Annually',
            parentValue: frequency
          },
          {
            label: 'Annually',
            value: 'Annually',
            parentValue: frequency
          },
          {
            label: 'One-Time',
            value: 'One-Time',
            parentValue: frequency
          }
        ]
      });
      filters.push({
        label: 'Created By',
        value: 'createdBy',
        children: [...createdBy]
          .sort((a, b) => {
            return a[0] < b[0] ? -1 : 1;
          })
          .map(([label, value]) => {
            const filterLabel = label === '--' ? '--' : label;
            const filterValue = value === '--' ? '--' : value;

            return { label: filterLabel, value: filterValue, parentValue: 'createdBy' };
          })
      });
    }

    if (fromQuestionnaire) {
      const preSelected: FilterListChild[] = this.appViewStore.tasksFilters[currentTab];
      preSelected.forEach((filter) => {
        const parent = filters.find((f) => f.value === filter.parentValue);
        if (parent) {
          const child = parent.children.find((c) => c.value === filter.value);
          if (child) {
            child.selected = true;
          }
        }
      });
    }
    return filters;
  };

  public toggleAutogen = (currentTab: TaskInternalExternal) => {
    this.viewModel.showAutogen = !this.viewModel.showAutogen;
    this.refilterResults(currentTab);
  };
}
